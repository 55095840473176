import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const events = [
  'search',
  'abort',
  'cancel',
  'change',
  'click',
  'close',
  'contextmenu',
  'cuechange',
  'dblclick',
  'drag',
  'dragstart',
  'drop',
  'input',
  'keydown',
  'keypress',
  'keyup',
  'mousedown',
  'reset',
  'resize',
  'seeked',
  'seeking',
  'select',
  'submit',
  'toggle',
  'volumechange',
  'auxclick',
  'pointerdown',
  'pointercancel',
  'selectstart',
  'selectionchange',
  'afterprint',
]

const Wrapper = styled.div`
  /* display: contents; */
  position: relative;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) translateZ(0);
    width: 20%;
    max-width: 128px;
    object-position: center;
    object-fit: cover;
    height: auto;
    filter: drop-shadow(0 20px 40px hsla(0, 0%, 0%, 0.3));
    transition: opacity 150ms ease;
    opacity: 0;
  }

  ${props =>
    props.play
      ? `
    cursor: pointer;
    svg {
      opacity: 1;
    }
  `
      : `svg {
        display: none;
      }`}
`

export function Video({
  video: { asset, autoplay, controls, sound, loop },
  ...props
}) {
  const video = useRef()
  const [paused, setPaused] = useState(true)

  useEffect(() => {
    if (!video.current) return
    setPaused(video.current.paused)
    video.current.addEventListener('play', () => setPaused(false))
    video.current.addEventListener('pause', () => setPaused(true))

    if (autoplay) {
      const autoplayInteractionListener = e => {
        if (video?.current?.paused) {
          try {
            video.current.play().then(() => {
              events.forEach(event => {
                window.removeEventListener(event, autoplayInteractionListener)
              })
            })
          } catch (error) {}
        }
      }

      events.forEach(event => {
        window.addEventListener(event, autoplayInteractionListener)
      })

      return () => {
        events.forEach(event => {
          try {
            window.removeEventListener(event, autoplayInteractionListener)
          } catch (error) {}
        })
      }
    } else {
      return () => {}
    }
  }, [video, autoplay])

  return (
    <Wrapper
      play={paused}
      onClick={() => {
        if (video.current && controls) {
          if (video.current.paused) {
            video.current.play()
          } else {
            video.current.pause()
          }
        }
      }}
    >
      {/* controls={controls} */}
      <video
        {...props}
        ref={video}
        playsInline
        loop={loop}
        autoPlay={autoplay}
        muted={!sound}
      >
        <source src={asset.url} type={asset.mimeType} />
        {asset.altText}
      </video>
      <svg width="72" height="72" viewBox="0 0 62 72">
        <polygon points="0,0,0,72,62,36" fill="white" stroke="none" />
      </svg>
    </Wrapper>
  )
}

Video.propTypes = {
  video: PropTypes.shape({
    asset: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    autoplay: PropTypes.bool.isRequired,
    controls: PropTypes.bool.isRequired,
    sound: PropTypes.bool.isRequired,
    loop: PropTypes.bool.isRequired,
  }).isRequired,
}
