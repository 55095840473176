import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import $ from 'jquery'

import theme from '../styled/theme'
import { ArrowLeft, ArrowRight } from './icons'

if (typeof window !== `undefined`) {
  require('slick-carousel')
}

const CarouselStyles = styled.div`
  ${props => !props.loaded && `opacity: 0;`}
  transition: opacity 0.3s ease-in;
  position: relative;

  /* Slider */
  .slick-slider {
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }

  .slick-list {
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
  }

  .slick-list:focus {
    outline: none;
  }

  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    transition-delay: 0s;
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .slick-track:before,
  .slick-track:after {
    display: table;

    content: '';
  }

  .slick-track:after {
    clear: both;
  }

  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    display: none;
    float: left;

    height: 100vw;
    min-height: 1px;
    max-width: 100%;
    border: 0.5px solid hsla(0, 0%, 15%, 1);
    box-sizing: border-box;
    position: relative;

    background: url('./loader.gif');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 32px 32px;
  }

  .slick-slide img {
    display: block;
    width: 100%;
  }

  .slick-slide.slick-loading img {
    display: none;
  }

  .slick-slide.dragging img {
    pointer-events: none;
  }

  .slick-initialized .slick-slide {
    display: block;
  }

  .slick-loading .slick-slide {
    visibility: hidden;
  }

  /* lazy load styles */
  .slick-slide img[data-lazy] {
    opacity: 0;
    filter: blur(5px);
  }

  .slick-slide img {
    opacity: 1;
    filter: none;
    transition: opacity 0.3s ease-in, filter .2s ease-in;
    width: 100%;
  }

  /* arrow styles */
  .slick-prev {
    left: 0;
    border-radius: 0 5rem 5rem 0;
  }

  .slick-next {
    right: 0;
    border-radius: 5rem 0 0 5rem;
  }

  .slick-arrow {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &:hover .slick-arrow {
    opacity: 1;
    transition: .5s ease;
  }

  &:hover .slick-arrow.slick-arrow-hidden {
    cursor: inherit;
    opacity: 0;
    transition: .5s ease;
  }

  @media only screen and (min-width: ${props => props.theme.sm}) {
    .slick-slide {
      height: 50vw;
      width: 50vw;
    }
  }

  @media only screen and (min-width: ${props => props.theme.md}) and (min-aspect-ratio: 1/1) {
    .slick-slide {
      height: 50vh;
      width: 50%;
    }
  }
`

const Arrow = styled.i`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);

  font-size: 0;
  line-height: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 3rem;
  height: 5rem;
  padding: 0;
  border: 0;

  cursor: pointer;

  color: ${props => props.theme.primary};
  outline: none;
  background: transparent;
  z-index: 1;

  opacity: 0;
  outline-width: 0;
  transition: opacity .5s ease;

  /* ${props => props.hideArrow && `pointer-events: none;`} */

  &:hover {
    color: ${props => props.theme.primary};
    background: transparent;
  }

  svg {
    filter: drop-shadow(0 0 4px hsla(0, 0%, 30%, .75));
  }
`

const Prev = props => <Arrow {...props} className={`slick-arrow slick-prev ${props.hideArrow && 'slick-arrow-hidden'}`}>
  <ArrowLeft />
</Arrow>

const Next = props => <Arrow {...props} className={`slick-arrow slick-next ${props.hideArrow && 'slick-arrow-hidden'}`}>
  <ArrowRight />
</Arrow>

const options = {
  arrows: false,
  infinite: true,
  lazyload: 'anticipated',
  mobileFirst: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
  responsive: [
    {
      breakpoint: parseInt(theme.sm) - 1, // from theme.sm onwards
      settings: {
        slidesToShow: 2,
      }
    }
  ],
}

function Carousel(props) {
  const [loaded, setLoaded] = useState(false)
  const [[hidePrev, hideNext], setVisibility] = useState([true, false])
  const ref = useRef()

  // interface for manipulating the slider
  const actions = {
    slickGoTo: (index) => ref && ref.current && $(ref.current).slick('slickGoTo', index),
    slickNext: () => ref && ref.current && $(ref.current).slick('slickNext'),
    slickPrev: () => ref && ref.current && $(ref.current).slick('slickPrev'),
    slickCurrentSlide: () => ref && ref.current && $(ref.current).slick('slickCurrentSlide'),
  }

  // updates visibility of prev/next arrows
  const updateArrows = (next = null) => {
    const [currentSlide, numSlides] = [next !== null ? next : actions.slickCurrentSlide(), props.children.length]

    if (currentSlide === 0) {
      setVisibility([true, false])
    } else if ((typeof window !== `undefined`) && (
      (window.innerWidth < parseInt(theme.sm) && currentSlide === numSlides - 1) // phone
      || (window.innerWidth >= parseInt(theme.sm) && currentSlide === numSlides - 2) // desktop
    )) {
      setVisibility([false, true])
    } else {
      setVisibility([false, false])
    }
  }

  // initialize and destroy carousel
  useEffect(() => {
    if (!ref || !ref.current || typeof window === `undefined`) return
    // init slider
    let slider = ref.current
    $(slider).slick(options)
    $(slider).on('beforeChange', function (event, slick, currentSlide, nextSlide) {
      updateArrows(nextSlide)
    })

    setLoaded(true)

    const resizeHandler = () => {
      window.requestAnimationFrame(() => {
        $(ref.current).slick('resize')
        updateArrows()
      })
    }

    if (typeof window !== `undefined`) window.addEventListener('resize', resizeHandler)

    return () => {
      if (typeof window !== `undefined`) window.removeEventListener('resize', resizeHandler)
      // restore the initial html layout to keep react from throwing errors
      $(slider).slick('unslick')
    }
  }, [])

  if (typeof props.setSlider === `function`) props.setSlider(actions)

  return <CarouselStyles loaded={loaded}>
    <div ref={ref}>
      {props.children}
    </div>
    <Prev onClick={actions.slickPrev} hideArrow={hidePrev} />
    <Next onClick={actions.slickNext} hideArrow={hideNext} />
  </CarouselStyles>
}

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
  setSlider: PropTypes.func
}

export default Carousel

export {
  Arrow
}
