import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

import GIF from './gif';

const SlideImage = props => {
  // Currently not a good idea :/ maybe in a few years, but then again gatsby images
  // will probably support animated WebPs...
  // https://corydowdy.com/blog/apng-vs-webp-vs-gif
  const imgString = `&q=90&fm=pjpg&auto=format`
  if (props.slide.asset.extension === 'gif') return <img src={props.slide.asset.fluid.src} alt="slide" />
  if (props.lazy) return <img data-lazy={props.slide.asset.fluid.src + imgString} src="" alt="slide" />
  if (1 === 1) return <img src={props.slide.asset.fluid.src + imgString} alt="slide" />
  return <Img
    fluid={props.slide.asset.fluid}
    fadeIn={true}
    durationFadeIn={150}
    loading="eager"
    /* style={{ pointerEvents: 'none' }} */
    placeholderStyle={{ display: 'none', filter: 'blur(10px)' }}
    alt="slide"
  />
}

SlideImage.propTypes = {
  slide: PropTypes.shape({
    _type: PropTypes.oneOf(['image']).isRequired,
    asset: PropTypes.shape({
      fluid: PropTypes.object.isRequired,
      extension: PropTypes.string.isRequired
    })
  }).isRequired,
  lazy: PropTypes.bool
}

const Slide = props => {
  switch (props.slide._type) {
    case 'mux.video':
      return <GIF asset={props.slide.asset} />
    case 'linkVideo':
      return <a href={props.slide.url} target="_blank" rel="nofollow noopener noreferrer">
        <GIF asset={props.slide.video.asset} />
      </a>
    case 'linkImage':
      return <a href={props.slide.url} target="_blank" rel="nofollow noopener noreferrer">
        <SlideImage slide={props.slide.image} lazy={props.lazy} />
      </a>
    default:
      return <SlideImage slide={props.slide} lazy={props.lazy} />
  }
}

Slide.propTypes = {
  slide: PropTypes.oneOfType([
    PropTypes.shape({
      _type: PropTypes.oneOf(['image']).isRequired,
      asset: PropTypes.shape({
        fluid: PropTypes.object.isRequired,
        extension: PropTypes.string.isRequired
      })
    }),
    PropTypes.shape({
      _type: PropTypes.oneOf(['mux.video']).isRequired,
      asset: PropTypes.shape({
        playbackId: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        thumbTime: PropTypes.string
      })
    })
  ]).isRequired,
  lazy: PropTypes.bool
}

export default Slide
