import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

const Wrapper = styled.section`
  color: ${props => props.theme.primary};
  background: ${props => props.theme.background};

  display: flex !important;
  justify-content: center;
  align-items: center;

  width: 100vw !important;
  height: 100vw;
  box-sizing: border-box;
  overflow: auto;
  overflow: overlay;

  text-transform: uppercase;
  text-align: center;
  font-style: normal;
  font-size: 4vw;

  @media screen and (min-width: ${props => props.theme.sm}) {
    width: 50vw !important;
    height: 50vw;
    font-size: 2vw;
  }

  @media screen and (min-width: ${props => props.theme.md}) and (min-aspect-ratio: 1/1) {
    width: 50vh !important;
    height: 50vh;
    font-size: 2vh;
  }

  address {
    font-style: normal;
  }

  a {
    color: ${props => props.theme.primary};
    text-decoration: none;

    &:hover,
    &:active {
      color: ${props => props.theme.accent};
    }
  }
`

const Phone = ({ number }) => (
  <a title="Anrufen" href={`tel:${number.replace(/\//g, '')}`}>{number}</a>
)

Phone.propTypes = {
  number: PropTypes.string.isRequired
}

Phone.defaultProps = {
  number: ''
}

const Email = ({ email }) => (
  <a title="Mail Senden" href={`mailto:${email}`} target="_blank" rel="noopener noreferrer">{email}</a>
)

Email.propTypes = {
  number: PropTypes.string.isRequired
}

Email.defaultProps = {
  number: ''
}

const Contact = props => {
  const { info } = useStaticQuery(
    graphql`
      query AboutQuery {
        info: sanityCompanyInfo(_id: {regex: "/(drafts.|)companyInfo/"}) {
          city
          country
          email1
          email2
          name
          phone1
          phone2
          street
        }
      }
    `
  )

  return (
    <Wrapper>
      <div>
        <address>
          {info.name || ''}<br />
          {info.street || ''}<br />
          {info.city || ''}, {info.country || ''}
        </address>
        <Phone number={info.phone1} />, <Phone number={info.phone2} /><br />
        <Email email={info.email1} /><br />
        <Email email={info.email2} />
      </div>
    </Wrapper>
  )
}

export default Contact