import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { Video } from './video'

const Wrapper = styled.div`
  margin-bottom: 1px;
  font-size: 0;
  line-height: 1;

  a {
    display: block;
  }

  video,
  img {
    object-fit: cover;
    min-height: 1px;
    max-width: 100%;
    height: 100vw;
    width: 100%;
  }

  @media only screen and (min-width: ${props =>
      props.theme.md}) and (min-aspect-ratio: 1/1) {
    video,
    img {
      height: 100vh;
      width: 100%;
    }
  }
`

function BannerContent({ bannerType, image, video }) {
  if (bannerType === 'video') {
    return <Video video={video} preload="auto" />
  } else {
    const imgString = `&q=90&fm=pjpg&auto=format`
    if (image.asset.extension === 'gif')
      return <img src={image.asset.fluid.src} alt="slide" />
    if (1 === 1)
      return <img src={image.asset.fluid.src + imgString} alt="slide" />
    return (
      <Img
        fluid={image.asset.fluid}
        fadeIn={true}
        durationFadeIn={150}
        loading="eager"
        /* style={{ pointerEvents: 'none' }} */
        placeholderStyle={{ display: 'none', filter: 'blur(10px)' }}
        alt="slide"
      />
    )
  }
}

export function Banner({
  banner: { published, bannerType, image, video, url },
}) {
  if (!published) return null
  return (
    <Wrapper>
      {url === null ? (
        <BannerContent bannerType={bannerType} image={image} video={video} />
      ) : (
        <a href={url} target="_blank" rel="noreferrer">
          <BannerContent bannerType={bannerType} image={image} video={video} />
        </a>
      )}
    </Wrapper>
  )
}

Banner.propTypes = {
  banner: PropTypes.shape({
    published: PropTypes.bool.isRequired,
    bannerType: PropTypes.oneOf(['image', 'video']).isRequired,
    image: PropTypes.shape({
      asset: PropTypes.shape({}),
    }),
    video: PropTypes.shape({
      asset: PropTypes.shape({
        url: PropTypes.string,
      }),
      autoplay: PropTypes.bool.isRequired,
      controls: PropTypes.bool.isRequired,
      sound: PropTypes.bool.isRequired,
    }),
    url: PropTypes.string,
  }).isRequired,
}
