import React from 'react'
import PropTypes from 'prop-types'
import BaseBlockContent from '@sanity/block-content-to-react'

const serializers = {
  types: {
    block(props) {
      switch (props.node.style) { // TODO ensure external links work
        default:
          return <p>{props.children}</p>
      }
    }
  }
}

const BlockText = ({ blocks }) => <BaseBlockContent blocks={blocks} serializers={serializers} />

BlockText.propTypes = {
  blocks: PropTypes.array.isRequired
}

export default BlockText