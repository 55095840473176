import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import BlockText from '../block-text'

const Wrapper = styled.section`
  color: ${props => props.theme.background};
  background: ${props => props.theme.primary};
  padding: 15px 30px;

  width: 100vw !important;
  height: 100vw;
  box-sizing: border-box;
  overflow: auto;
  overflow: overlay;

  text-align: center;
  font-style: normal;
  font-size: 4vw;

  @media screen and (min-width: ${props => props.theme.sm}) {
    width: 50vw !important;
    height: 50vw;
    font-size: 2vw;
  }

  @media screen and (min-width: ${props => props.theme.md}) and (min-aspect-ratio: 1/1) {
    width: 50vh !important;
    height: 50vh;
    font-size: 2vh;
  }

  a {
    color: ${props => props.theme.background};
    text-decoration: underline;

    &:hover,
    &:active {
      color: ${props => props.theme.accent};
      text-decoration: underline;
    }
  }
`

const Privacy = props => {
  const { privacy } = useStaticQuery(
    graphql`
      query PrivacyQuery {
        privacy: sanityPrivacy(_id: {regex: "/(drafts.|)privacy/"}) {
          content: _rawBody
        }
      }
    `
  )

  return (
    <Wrapper>
      {privacy.content && <BlockText blocks={privacy.content} />}
    </Wrapper>
  )
}

export default Privacy