import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import IndexPage from '../components/index-page'
import GraphQLErrorList from '../components/graphql-error-list'

export const query = graphql`
  query IndexPageQuery {
    clients: allSanityClient(
      filter: { published: { eq: true } }
      sort: { fields: order, order: ASC }
    ) {
      edges {
        node {
          gallery {
            slides {
              ... on SanityImage {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                  extension
                }
                _type
                _key
              }
              ... on SanityMuxVideo {
                asset {
                  playbackId
                  status
                  thumbTime
                }
                _type
                _key
              }
              ... on SanityLinkImage {
                image {
                  asset {
                    fluid {
                      ...GatsbySanityImageFluid
                    }
                    extension
                  }
                  _type
                  _key
                }
                url
                _type
                _key
              }
              ... on SanityLinkVideo {
                video {
                  asset {
                    playbackId
                    status
                    thumbTime
                  }
                  _type
                  _key
                }
                url
                _type
                _key
              }
            }
          }
          _id
        }
      }
    }
    banner: sanityBanner(_id: { regex: "/(drafts.|)banner/" }) {
      published
      bannerType
      image {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
          extension
        }
      }
      video {
        asset {
          mimeType
          altText
          assetId
          extension
          url
        }
        autoplay
        controls
        sound
        loop
      }
      url
    }
    about: sanityAbout(_id: { regex: "/(drafts.|)about/" }) {
      gallery {
        slides {
          ... on SanityCompanyInfo {
            _type
            _id
          }
          ... on SanityImage {
            _type
            _key
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
              extension
            }
          }
          ... on SanityMuxVideo {
            _type
            _key
            asset {
              playbackId
              status
              thumbTime
            }
          }
          ... on SanityPrivacy {
            _type
            _id
          }
          ... on SanityAboutImage {
            _type
            _id
          }
          ... on SanityLinkImage {
            image {
              asset {
                fluid {
                  ...GatsbySanityImageFluid
                }
                extension
              }
              _type
              _key
            }
            url
            _type
            _key
          }
          ... on SanityLinkVideo {
            video {
              asset {
                playbackId
                status
                thumbTime
              }
              _type
              _key
            }
            url
            _type
            _key
          }
        }
      }
    }
  }
`

const Index = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const clients = (data.clients || {}).edges.map(edge => edge.node) || []
  const about = data.about || {}

  return (
    <Layout>
      <SEO />
      <IndexPage about={about} clients={clients} banner={data.banner} />
    </Layout>
  )
}

Index.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.array,
}

export default Index
