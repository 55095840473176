import React from 'react'
import PropTypes from 'prop-types'

import Slide from './slide'
import Contact from './contact'
import Privacy from './privacy'
import AboutImage from './about-image'

const AboutSlide = props => {
  switch (props.slide._type) {
    case 'companyInfo':
      return <Contact />
    case 'privacy':
      return <Privacy />
    case 'aboutImage':
      return <AboutImage />
    default:
      return <Slide slide={props.slide} lazy={props.lazy} />
  }
}

AboutSlide.propTypes = {
  slide: PropTypes.oneOfType([
    PropTypes.shape({
      _type: PropTypes.oneOf(['image']).isRequired,
      asset: PropTypes.shape({
        fluid: PropTypes.object.isRequired,
        extension: PropTypes.string.isRequired
      }).isRequired
    }),
    PropTypes.shape({
      _type: PropTypes.oneOf(['mux.video']).isRequired,
      asset: PropTypes.shape({
        playbackId: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        thumbTime: PropTypes.string.isRequired
      }).isRequired
    }),
    PropTypes.shape({
      _type: PropTypes.oneOf(['aboutImage', 'companyInfo', 'privacy']).isRequired,
    }).isRequired
  ]).isRequired,
  lazy: PropTypes.bool
}

export default AboutSlide