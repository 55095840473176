import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { ArrowRight, ArrowDown } from './icons'
import { Arrow } from './carousel'

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vw;
  pointer-events: none;
  z-index: 1000;
  box-sizing: border-box;
  /* transform: rotate(90deg); */
  @media screen and (min-width: ${props => props.theme.sm}){
    display: none;
  }
`

const LegendArrow = styled(Arrow)`
  opacity: ${props => props.shown ? 1 : 0};
  pointer-events: ${props => props.shown ? 'all' : 'none'};
  cursor: pointer;
`

const LegendText = styled.span`
  position: absolute;
  font-size: 4vw;
  text-shadow: 0 0 4px hsla(0, 0%, 30%, .75);
  transition: opacity .5s ease;
  opacity: ${props => props.shown ? 1 : 0};
  pointer-events: ${props => props.shown ? 'all' : 'none'};
`

const ProjectsWrapper = styled(LegendText)`
  left: 50%;
  bottom: 12vw;
  transform: translate(-50%, 0);
`

const Projects = props => <ProjectsWrapper shown={props.shown}>PROJECTS</ProjectsWrapper>

const AboutWrapper = styled(LegendText)`
  top: 50%;
  right: 9.5vw;
  transform: translate(0, -50%) rotate(-90deg);
`

const About = props => <AboutWrapper shown={props.shown}>ABOUT</AboutWrapper>

const ProjectsArrow = styled(props => <LegendArrow {...props} className={`${props.className || ''} ${props.hidden ? 'slick-arrow-hidden' : ''}`}>
  <ArrowDown />
</LegendArrow>)`
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 0;
  top: initial;
  height: 3rem;
  width: 5rem;
  border-radius: 5rem 5rem 0 0;
  box-sizing: border-box;
`

const AboutArrow = styled(props => <LegendArrow {...props} className={`${props.className || ''} ${props.hidden ? 'slick-arrow-hidden' : ''}`}>
  <ArrowRight />
</LegendArrow>)`
  right: 0;
  border-radius: 5rem 0 0 5rem;
`

const Legend = props => <Wrapper>
  <About shown={props.shown} onClick={props.handleAbout} />
  <AboutArrow shown={props.shown} onClick={props.handleAbout} />
  <Projects shown={props.shown} onClick={props.handleProjects} />
  <ProjectsArrow shown={props.shown} onClick={props.handleProjects} />
</Wrapper>

Legend.propTypes = {
  shown: PropTypes.bool.isRequired,
  handleAbout: PropTypes.func.isRequired,
  handleProjects: PropTypes.func.isRequired,
}

export default Legend