import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Slide from './slide'

const AboutImage = props => {
  const { aboutImage: {
    image
  } } = useStaticQuery(graphql`
    query AboutImageQuery {
      aboutImage: sanityAboutImage(_id: {regex: "/(drafts.|)aboutImage/"}) {
        image {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
            extension
          }
          _type
        }
      }
    }
  `)
  return <Slide slide={image} lazy={true} />
}

export default AboutImage