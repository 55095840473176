import React from 'react'
import PropTypes from 'prop-types'
import SanityMuxPlayer from 'sanity-mux-player'
import styled from 'styled-components'

const UnstyledGIF = props => (
  <SanityMuxPlayer
    assetDocument={props.asset}
    autoload={true}
    autoplay={true}
    loop={true}
    muted={true}
    showControls={false}
  />
)

UnstyledGIF.propTypes = {
  asset: PropTypes.shape({
    playbackId: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    thumbTime: PropTypes.number.isRequired,
  }).isRequired
}

const GIF = styled(UnstyledGIF)`
  video {
    width: 100vw;
    object-fit: cover;
    object-position: 50% 50%;
    height: 100vw;

    @media only screen and (min-width: 600px){
      width: 50vw;
      height: 50vw;
    }
  }
`

export default GIF