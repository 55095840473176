import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import smoothscroll from 'smoothscroll-polyfill'

import { debounce } from '../lib/util'
import Slider from './carousel'
import Slide, { AboutSlide } from './slide'
import { ArrowUp } from './icons'
import Legend from './legend'

import 'normalize.css/normalize.css'
import './base.css'
import { Banner } from './banner'

const Footer = styled.footer`
  width: 100vw;
  height: 100vw;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  font-size: 4vw;

  @media screen and (min-width: ${props => props.theme.sm}) {
    height: 50vw;
    font-size: 2vw;
  }

  @media screen and (min-width: ${props =>
      props.theme.md}) and (min-aspect-ratio: 1/1) {
    width: 100vh;
    height: 50vh;
    font-size: 2vh;
  }

  a {
    color: ${props => props.theme.primary};
    text-decoration: none;

    &:hover,
    &:active {
      color: ${props => props.theme.accent};
      text-decoration: none;
    }
  }
`

class IndexPage extends React.Component {
  constructor(props) {
    super(props)

    this.contactIndex = this.props.about.gallery.slides.findIndex(
      slide => slide._type === 'companyInfo'
    )
    if (this.contactIndex === -1) this.contactIndex = 0

    this.aboutIndex = this.props.about.gallery.slides.findIndex(
      slide => slide._type === 'aboutImage'
    )
    if (this.aboutIndex === -1) this.aboutIndex = 0

    this.sliders = Array(this.props.clients.length + 1)

    this.state = {
      showLegend: true,
    }
    this.legendTimeout = undefined
  }

  componentDidMount = () => {
    smoothscroll.polyfill()
    this.showLegend()
    window.addEventListener(
      'scroll',
      debounce(() => {
        if (window.scrollY >= 2 * window.innerWidth) this.showLegend()
      }, 25)
    )
  }

  componentWillUnmount = () => {
    window.removeEventListener(
      'scroll',
      debounce(() => {
        if (window.scrollY >= 2 * window.innerWidth) this.showLegend()
      }, 25)
    )
  }

  showLegend = () => {
    clearTimeout(this.legendTimeout)
    this.setState({ showLegend: true })
    this.legendTimeout = setTimeout(
      () => this.setState({ showLegend: false }),
      2000
    )
  }

  toHome = e => {
    this.scrollToTop(e)
    this.sliders.forEach(slider => slider.slickGoTo(0))
  }

  toContact = e => {
    this.scrollToTop(e)
    this.sliders[0].slickGoTo(this.contactIndex)
  }

  toAgency = e => {
    this.scrollToTop(e)
    this.sliders[0].slickGoTo(this.aboutIndex)
  }

  scrollToTop = e => {
    e.preventDefault() // keep relative link fallback from firing
    window.scroll({ top: 0, left: 0, behavior: 'smooth' })
  }

  setSlider = (slider, index) => (this.sliders[index] = slider)

  handleAbout = () => {
    this.setState({
      showLegend: false,
    })
    this.sliders[0].slickNext()
  }

  handleProjects = () => {
    this.setState({
      showLegend: false,
    })
    window.scroll({ top: window.innerWidth, left: 0, behavior: 'smooth' })
  }

  render() {
    return (
      <>
        {this.props.banner.published && <Banner banner={this.props.banner} />}
        <Slider id="top" setSlider={slider => this.setSlider(slider, 0)}>
          {this.props.about.gallery.slides.map((slide, index) => (
            <AboutSlide
              key={slide._key || slide._id}
              slide={slide}
              lazy={
                index < 3 ||
                index === this.props.about.gallery.slides.length - 1
              }
            />
          ))}
        </Slider>
        <Legend
          shown={this.state.showLegend}
          handleAbout={this.handleAbout}
          handleProjects={this.handleProjects}
        />
        {this.props.clients.map((client, index) => (
          <Slider
            key={client._id}
            setSlider={slider => this.setSlider(slider, index + 1)}
          >
            {client.gallery.slides.map((slide, index) => (
              <Slide
                key={slide._key}
                slide={slide}
                lazy={index < 3 || index === client.gallery.slides.length - 1}
              />
            ))}
          </Slider>
        ))}
        <Footer className="Footer Footer__container">
          <a
            className="Footer Footer__link"
            href="#top"
            onClick={this.scrollToTop}
            title="Zum Seitenanfang"
            alt="Zum Seitenanfang"
          >
            <ArrowUp />
          </a>
          <a
            className="Footer Footer__link"
            href="#top"
            onClick={this.toContact}
          >
            contact
          </a>
          <a
            className="Footer Footer__link"
            href="#top"
            onClick={this.toAgency}
          >
            agency
          </a>
          <a className="Footer Footer__link" href="#top" onClick={this.toHome}>
            home
          </a>
        </Footer>
      </>
    )
  }
}

IndexPage.propTypes = {
  about: PropTypes.shape({
    gallery: PropTypes.shape({
      slides: PropTypes.array.isRequired,
    }).isRequired,
  }).isRequired,
  clients: PropTypes.arrayOf(
    PropTypes.shape({
      gallery: PropTypes.shape({
        slides: PropTypes.array.isRequired,
      }).isRequired,
    })
  ).isRequired,
  banner: PropTypes.shape({
    published: PropTypes.bool.isRequired,
    bannerType: PropTypes.oneOf(['image', 'video']).isRequired,
    image: PropTypes.shape({
      asset: PropTypes.shape({}),
    }),
    video: PropTypes.shape({
      asset: PropTypes.shape({
        url: PropTypes.string,
      }),
      autoplay: PropTypes.bool.isRequired,
      controls: PropTypes.bool.isRequired,
      sound: PropTypes.bool.isRequired,
    }),
    url: PropTypes.string,
  }).isRequired,
}

export default IndexPage
